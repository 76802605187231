import React from 'react';

import ProjectCard from './../../Components/ProjectCard/ProjectCard';

import './styles.css';

export default (props: any) => (
	<div>

		<div className='jumbotron jumbotron-fluid job-opening-jumbo orangeBackground'>
			<div className='container d-table' style={{height: '100%'}}>
				<div className='text-center d-table-cell align-middle'>
					<div className='jobHeader'>Our projects</div>
					<div className='jobSecondHeader'>See what we've been up to</div>
				</div>
			</div>
		</div>

		<div className='mt-5 mb-5 row no-gutters justify-content-center'>
			<div className='row'>
					<div className='col-sm-12 col-md-5 col-lg-4'>
						<ProjectCard
							title='Bonadea'
							text='Dit is een test kaart voor een project'
							onClick={() => props.navigation.navigate('ProjectDetails', {id: 1})}
							buttonText='Klik'
						/>
					</div>
					<div className='col-sm-12 col-md-6 col-lg-4'>
						<ProjectCard
							title='Bonadea'
							text='Dit is een test kaart voor een project'
							onClick={() => props.navigation.navigate('ProjectDetails', {id: 1})}
							buttonText='Klik'
						/>
					</div>
					<div className='col-sm-12 col-md-6 col-lg-4'>
						<ProjectCard
							title='Bonadea'
							text='Dit is een test kaart voor een project'
							onClick={() => props.navigation.navigate('ProjectDetails', {id: 1})}
							buttonText='Klik'
						/>
					</div>
			</div>
		</div>

        {/*

		<div>
			<div className='row no-gutters justify-content-center paddedRow jumbotron'>
				<div className='text-center'>
					<h3>Projects</h3>
					<div>
						We specialise in rapidly building web and mobile apps, using rapid prototyping.
					</div>

					<div className='btn btn-outline-dark job-button'>Check out some of our projects</div>
				</div>
			</div>
		</div>
        */}
	</div>
);
