import React from 'react';

import './styles.css';

import * as projects from './projects.json';

interface IProject {
	id: number;
	name: string;
	text: string;
	image: string;
	navigation: any;
}

export default class ProjectDetails extends React.Component<IProject> {

	public state = {
		project: undefined,
	};

	public componentDidMount(): void {
		const id = this.props.navigation.getParam('id', 0);

		const findProject = projects.Projects.filter( (result) => result.id === Number(id) )[0];
		this.setState({project: findProject});
	}

	public render() {

		const project: any = this.state.project;

		if (!this.state.project) {
			return (
				<div className='row justify-content-center'>
					<div>Loading...</div>
				</div>
			);
		}

		return (
			<div>
				<div className='jumbotron jumbotron-fluid project-details-jumbo'
					 style={{marginBottom: 0, backgroundImage: `url(${project.image})`}}>
					<div className='container d-table' style={{height: '100%'}}>
						<div className='text-center d-table-cell align-middle'>
							<div className='jobHeader project-details-jumbo-header'>{project.name}</div>
						</div>
					</div>
				</div>

				<div className='container'>
					<div className='project-title-text'>
						<h2>{project.header}</h2>
					</div>

					<div className='body-text-div'>
						<p>{project.text}</p>
					</div>
				</div>
			</div>
		);
	}
}
