import { combineReducers } from 'redux';

import config from './../../config/general.json';

const INITIAL_STATE = {
	config,
	current: 'en',
	languages: [
		'en',
		'nl',
	],
};

const appReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case 'SET_LANGUAGE':
			const tmpState0 = Object.assign({}, state);

			tmpState0.current = action.payload;

			return tmpState0;

		default:
			return state;
  	}
};

export default combineReducers({
  	app: appReducer,
});
