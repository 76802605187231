import React from 'react';
import ReactDom from 'react-dom';

import App from './App';

// import register from './registerServiceWorker';

import './index.css';

ReactDom.render(<App />, document.getElementById('root'));
// register();
