import ITranslations from './../ITranslations';

const EN: ITranslations = {
    whatWeDo: `what we do`,
    howWeDoIt: `How we do it`,
    whatComesOut: `What comes out`,

    // Hamburger Menu
    home: `home`,
    services: `Services`,
    projects: `projects`,
    jobs: `jobs`,
    contact: `contact`,

    // Home
    slogan: `Don't Dream It. Make It.`,
    subSlogan: 'This jumbotron is super duper wide!',
    startHere: 'START HERE',

    // What We do
    prototyping: 'Prototyping ',

    prototypingIntro: `Innovation requires validation, whether it is solving an existing problem or exploring a new idea. Prototyping  prevents strategic mistakes. We help you translate your needs into a functional prototype, which we can evolve into a minimum viable product.`,
    // prototypingIntro: `You only understand the problem once you have developed a solution. No right/no wrong answer (only better/or worse).	When is good good enough? There is no stopping rule`,
    prototypingButton: 'Simply Start.',

    strategy: 'Digital Strategy',
    strategyIntro: `A future-proof vision empowers you to make smart decisions, improve market position, and reduce costs. Our engineers work with the lastest technologies, that challenge existing limitations. With this know-how, we convert short- & long-term company goals into an effective IT strategy.`,
    strategyButton: 'Learn more',

    continiousdevelopment: 'Continuous Development',
    continiousdevelopmentIntro: 'As a business, your needs can change, and your software has to change with you. MakeTek develops new features and adapts existing software in short development cycles, allowing you to shift fast and keep your competitive edge.',
    continiousdevelopmentButton: 'Learn more.',

    innovationlaunch: 'Innovation Launch',
    innovationlaunchIntro: 'A solid foundation for a new project determines its success. Our Project initiation package gives your team hands-on guidance and supervision to define and manage the project goals, scope, and potential pitfalls. This launching process provides reference points throughout the project, and lets your team excel.',
    innovationlaunchButton: 'Learn more.',

    designThinking: 'Design Thinking',
    designThinkingText: 'The complexity of modern software development requires a more iterative and collaborative approach. We implement Design thinking to resolve vaguely defined problems, adopt a solution-focused strategy and use prototyping to validate and test our solutions. By repeating this process in multiple iterations, we end up with a proven problem definition, and a solution tailored to solve.',
    designThinkingButton: 'Learn more',

    tekGears: 'Tek Gears',
    tekGearsText: `Software projects often consist of unique features that do not exist yet, and support features that have already been developed before. Both are necessary to reach the project goal. To prevent these support features taking up too much time, we at MakeTek have developed more than 50 microservices that offer these features out-of-the-box.`,
    tekGearsButton: 'Stop reinventing the wheel',

    projectWd: 'Waterdrinker',
    projectWdLead: 'B2C warehouse manager',
    projectWdText: 'In cooperation with the Waterdrinker Aalsmeer, we developed a realtime warehouse system working together with a large group of external systems',
    projectWdButton: 'Learn more',

    projectTff: 'The Flower Family',
    projectTffLead: 'Flower e-commerce and delivery system',
    projectTffText: 'The Flower Family is a online Flower subscription, at MakeTek we developed the front-end e-commerce webshop and mobile application, and the logistics routing algorithm.',
    projectTffButton: 'Check out this project',

	welcomeMessage: 'Welcome to the MakeTek starter kit!',
	currentLanguage: 'The currently selected language is:',
	counterPage: 'This is the todos page.',

	// Todo component
	id: 'id',
	userid: 'User id',
	title: 'title',

	// Home page

};

export default EN;
