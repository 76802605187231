import * as React from 'react';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLanguage } from './../../store/actions/AppActions';

import icon_nl from './../../assets/images/country_icons/237-netherlands.png';
import icon_en from './../../assets/images/country_icons/260-united-kingdom.png';

import './styles.css';

class LanguageSwitcher extends React.Component<any> {

	public render() {
		return (
			<div className='dropdown'>
                <button className='btn btn-light dropdown-toggle' type='button' id='dropdownMenuButton'
                    data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' >

                    {this.props.app.current === 'nl' ?
                        <img src={icon_nl} alt='Nederlands' style={{ width: '25px', height: '25px', marginRight: '1em' }} />
                        :
                        <img src={icon_en} alt='English' style={{ width: '25px', height: '25px', marginRight: '1em' }} />
                    }
                    {this.props.app.current === 'nl' ?
                        <span>nl</span> : <span>en</span>
                    }
				</button>
				<div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
					<span className='dropdown-item' onClick={() => this.props.setLanguage('en')}>English</span>
                    {/*<span className='dropdown-item' onClick={() => this.props.setLanguage('nl')}>Nederlands</span>*/}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	const { app } = state;
	return { app };
};

const mapDispatchToProps = (dispatch: any) => (
	bindActionCreators({
		setLanguage,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
