export default class AppStorage {

	public async storeData(key: string, item: any): Promise<void> {
		try {
			await localStorage.setItem(`@TFF_Store:${key}`, item);
		} catch (e) {
			// Error saving data
			console.log(e);
		}
	}

	public retrieveData(key: string): string | undefined | null {
		try {
			const value = localStorage.getItem(`@TFF_Store:${key}`);

			if (value !== null) {
				return value;
			}

			return null;

		} catch (e) {
			// Error retrieving data
			console.log(e);
		}
	}

	public removeItem(key: string): void {
		try {
			localStorage.removeItem(`@TFF_Store:${key}`);
		} catch (e) {
			// Error removing data
			console.log(e);
		}
	}
}
