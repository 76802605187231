import ITranslations from './../ITranslations';

const NL: ITranslations = {
    whatWeDo: `wat wij doen`,
    howWeDoIt: `hoe doen wij dit`,
    whatComesOut: `en het resultaat`,

    // Hamburger Menu
    home: `home`,
    services: `diensten`,
    projects: `projecten`,
    jobs: `vacatures`,
    contact: `contact`,

    // Home pagina
    slogan: `Don't Dream It. Make It.`,
    subSlogan: 'Dit is een Jumbotron die de volledige breedte van de pagina heeft.',
    startHere: 'START HIER',

    // What We do
    prototyping: 'Prototypes',
    prototypingIntro: `You only understand the problem when you have developed a solution. No right/no wrong answer (only better/or worse).	When is good good enough? There is no stopping rule`,
    prototypingButton: 'Begin nu',

    strategy: 'Digital Strategy',
    strategyIntro: `Reaching new goals can be a challenge MakeTek is your goto partner in digital transition`,
    strategyButton: 'Learn more',

    continiousdevelopment: 'Continuous Development',
    continiousdevelopmentIntro: 'Scale your business with a partner that can scale with you.',
    continiousdevelopmentButton: 'Learn more.',

    innovationlaunch: 'Innovation Launch',
    innovationlaunchIntro: 'Starting your own software project can be a challenge, use our expertise to give your project the headstart it deserves.',
    innovationlaunchButton: 'Learn more.',

    designThinking: 'Design Thinking',
    designThinkingText: 'innovation should be human-centered. At MakeTek we work together with you to create the best solutions',
    designThinkingButton: 'Onze aanpak',

    tekGears: 'Tek Gears',
    tekGearsText: 'Pool from our existing services, reduce cost, focus on your unique edge',
    tekGearsButton: 'Meer info',

    projectWd: 'Waterdrinker',
    projectWdLead: 'B2C warehouse manager',
    projectWdText: 'Some text to be added',
    projectWdButton: 'Learn more',

    projectTff: 'The Flower Family',
    projectTffLead: 'Flower e-commerce and delivery system',
    projectTffText: 'Together with The Flower Family we created an IT infrastructure to suit their needs.',
    projectTffButton: 'Check out this project',

	welcomeMessage: 'Welkom op de MakeTek starter kit app!',
	currentLanguage: 'De huidig geslecteerde taal is:',
	counterPage: 'Dit is de todos pagina.',

	// Todo component
	id: 'id',
	userid: 'gebruiker id',
	title: 'titel',
};

export default NL;
