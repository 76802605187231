// import { Platform } from 'react-native';
const AppStorage = require('./AppStorage');

/**
 * This class handles localstorage on both phone and web.
 * All platforms use the same functions.
 *
 * Example implementation:
 *
 * import Storage from './../Components/CrossPlatform/Storage/Storage';
 * const storage = new Storage;
 *
 * // Store data
 * await storage.storeData('user', { name: someSame, phone: someNumber });
 *
 * // Fetch data
 * const result = storage.retrieveData('user');
 */
export default class Storage {

	private storage = new AppStorage.default();

	public async retrieveData(key: string) {
		const storage =  await this.storage.retrieveData(key);
		return JSON.parse(storage);
	}

	public storeData(key: string, value: any) {
		this.storage.storeData(key, JSON.stringify(value));
	}

	public removeData(key: string): void {
		this.storage.removeItem(key);
	}

	public storeDataToList(key: string, item: any) {
		try {

			this.retrieveData(key).then( (result) => {
				if (!result) {
					result = [];
				}

				result.push(item);

				this.storeData(key, result);
			});

		} catch (e) {
			// Error saving data
			console.log(e);
		}
	}

	public removeItemFromList(key: string, id: number, variableKey?: string): void {
		try {
			// First retrieve the list we wish to remove an item from.
			this.retrieveData(key).then( (list: any[]) => {

				let position: number = -1;

				// Loop over the list and find the item we wish to remove.
				list.map( (item, index) => {
					// If a variable key has been give, use this to look for an ID inside of a different object inside the list object.
					// Example: object { flower: {id: 1, name: "Roos"}, bundle: {id: 1, name: 'large'}};
					// variableKey will be: "flower". this will look for the given ID inside the flower, instead of the main object.
					if (variableKey) {
						if (item[variableKey].id === id) {
							position = index;
						}
					} else {
						if (item.id === id) {
							position = index;
						}
					}
				});

				// Only splice if an item has been found.
				if (position !== - 1) {
					list.splice(position, 1);
					this.storeData(key, list);
				}
			});
		} catch (e) {
			console.log(e);
		}
	}
}
