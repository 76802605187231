import ITranslations from './ITranslations';
import EN from './Languages/en';
import NL from './Languages/nl';

export default class LOC {

	public static current: ITranslations = EN;

	public language: any = {
		nl: NL,
		en: EN,
	};

	public static getLanguage() {
		return LOC.current;
	}

	public setLanguage(chosenLanguage: string): ITranslations {
		return LOC.current = this.language[chosenLanguage];
	}
}
