import * as React from 'react';

import './styles.css';
import plants from './../../assets/images/plants.jpg';

interface IProjectCard {
	title: string;
	text: string;
	onClick: () => {};
	buttonText: string;
}

export default (props: IProjectCard) => {

	return (
		<div className='row no-gutters animated fadeIn fast'>
			<div className='animated fadeInLeft'>
				<div className='project-card-size project-card-shadow'>
					<div style={{width: '100%', height: '70%'}}>
						<img src={plants} alt='plants' width='100%' height='100%' />
					</div>

					<div className='project-card-text-indent'>
						<h3>{props.title}</h3>

						<p>{props.text}</p>

						<span
							className='btn btn-outline-dark margin-bottom-14'
							onClick={() => props.onClick()}
						>
							{props.buttonText}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
