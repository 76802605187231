// Checks what envoirnment is running (dev or production) and returns the correct config file.
let config: JSON;

if (process.env.REACT_APP_NODE_ENV !== 'production') {
	config = require('./config.development.json');
} else {
	config = require('./config.production.json');
}

export default config;
