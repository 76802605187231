import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import logo from './../../assets/images/Mtek512x512.png';

import { Helmet } from 'react-helmet';
import './styles.css';
import googleAnalytics from '../../Util/analytics/GoogleAnalytics';

export default class Contact extends React.Component<any> {

	public componentDidMount(): void {
		window.scrollTo(0, 0);
    }

    public render() {

        const jsonld = {
            '@context': 'http://schema.org/',
            '@type': 'Webpage',
            'name': 'MakeTek - Contact',
            'image': logo,
            'description': 'Interested in our services? Get in touch with us',
            'url': 'https://www.maketek.nl/contact',
        };

        googleAnalytics.getInstance().hit(this.props.navigation.state.key);

		    return (
            <div>
                <Helmet>
                    <title> Contact MakeTek -  How to reach us  </title>
                    <link rel='canonical' href='https://www.maketek.nl/contact' />
                    <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld)}</script>
                </Helmet>

				<div className='jumbotron jumboHeight orangeBackground'>
					<span className='text-center jumboDisplay contactHeader'>Contact</span>
				</div>

				<div className='container smallScreenMarginTop'>
					<div className='row justify-content-center'>
						<div className='card cardWidth cardPadding'>
							<div className='row no-gutters'>
								<div className='col-3 d-flex flex-column justify-content-center'>
									<FontAwesomeIcon icon={faPhone} size='4x' color='#FFA626' />
								</div>
								<div className='col-9'>
									<h5 className='card-title'>Call us</h5>
									<p className='card-text'>You can reach us from Tuesday to Friday between 09:00 and 18:00 on +31 (0)15 455 0539</p>
								</div>
							</div>
						</div>

						<div className='card cardWidth cardPadding largeScreenMargin'>
							<div className='row no-gutters'>
								<div className='col-3 d-flex flex-column justify-content-center'>
									<FontAwesomeIcon icon={faEnvelopeOpenText} size='4x' color='#ff9c00' />
								</div>
								<div className='col-9'>
									<h5 className='card-title'>Send us an E-mail</h5>
									<p className='card-text'>ilja@maketek.nl</p>
								</div>
							</div>
						</div>
					</div>

					<section className='marginSection'></section>

					<form
						id='contactform'
						className='gform'
						method='POST'
						action='https://script.google.com/macros/s/AKfycbz45UaEkVoxwJbbuAWXWIZNPYoD0I2uWcgVHqEp0alPxWr6BFEx_lOniepX_o_1jWB9/exec'>
						<h4 className='text-center formHeader'>Leave us a message</h4>
						<div className='form-row'>
							<div className='form-group col-sm-12 col-lg-6 inputPadding'>
								<input name='name' type='text' className='form-control' placeholder='Name*' />
								<input name='email'  type='text' className='form-control' placeholder='E-mail*' />
								<input name='phone' type='text' className='form-control' placeholder='Phone*' />
							</div>
							<div className='form-group col-sm-12 col-lg-6 inputPadding'>
								<textarea name='message' form='contactform' className='form-control' rows={4} placeholder='Your message' />
							</div>
						</div>
						<button type='submit' className='btn btn-primary mb-2'>Send</button>
					</form>
				</div>

				<iframe
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1045.488088502578!2d4.372236129291374!3d51.
                9825104247213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b5787120e2ed%3A0x6a89f70e98b010
                72!2sSchieweg+93%2C+2627+AT+Delft!5e1!3m2!1sen!2snl!4v1560072029239!5m2!1sen!2snl'
					frameBorder='0' className='iFrameStyles' allowFullScreen>
				</iframe>

			</div>
		);
	}
}
